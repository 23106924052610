import { type Freight, FreightAction, FreightStatusCode } from '@/types/Freight'
import {
  TransportationActionDTO,
  type TransportationDTO,
  TransportationStatusCodeDTO
} from '@/types/api/Transportation/TransportationDTO'

export const mapFreightStatus = (status: TransportationStatusCodeDTO): FreightStatusCode => {
  switch (status) {
    case TransportationStatusCodeDTO.NEW:
      return FreightStatusCode.NEW

    case TransportationStatusCodeDTO.ASSIGNED:
      return FreightStatusCode.ASSIGNED

    case TransportationStatusCodeDTO.ACCEPTED:
      return FreightStatusCode.ACCEPTED

    case TransportationStatusCodeDTO.ARRIVED_LADING:
      return FreightStatusCode.ARRIVED_LADING

    case TransportationStatusCodeDTO.DEPARTED_LADING:
      return FreightStatusCode.DEPARTED_LADING

    case TransportationStatusCodeDTO.ARRIVED_UNLADING:
      return FreightStatusCode.ARRIVED_UNLADING

    case TransportationStatusCodeDTO.STARTED_UNLADING:
      return FreightStatusCode.STARTED_UNLADING

    case TransportationStatusCodeDTO.DEPARTED_UNLADING:
      return FreightStatusCode.DEPARTED_UNLADING

    case TransportationStatusCodeDTO.ARRIVED_EMPTY:
      return FreightStatusCode.ARRIVED_EMPTY

    case TransportationStatusCodeDTO.PASSED_EMPTY:
      return FreightStatusCode.PASSED_EMPTY

    case TransportationStatusCodeDTO.FINISHED:
      return FreightStatusCode.FINISHED

    case TransportationStatusCodeDTO.CANCELLED:
      return FreightStatusCode.CANCELLED

    case TransportationStatusCodeDTO.RECOMMENDED:
      return FreightStatusCode.RECOMMENDED
  }
}

export const mapFreightAction = (action: TransportationActionDTO): FreightAction => {
  switch (action) {
    case TransportationActionDTO.ASSIGN:
      return FreightAction.ASSIGN

    case TransportationActionDTO.CANCEL:
      return FreightAction.CANCEL

    case TransportationActionDTO.APPROVE_OR_REJECT:
      return FreightAction.APPROVE_OR_REJECT

    case TransportationActionDTO.REPORT_GENERATE:
      return FreightAction.REPORT_GENERATE

    case TransportationActionDTO.INSTRUCTION_EDIT:
      return FreightAction.INSTRUCTION_EDIT
  }
}

export const mapTransportationDTOToFreight = (transportationDTO: TransportationDTO): Freight => {
  return {
    id: transportationDTO.id,
    date: transportationDTO.dateStart ? new Date(transportationDTO.dateStart).getTime() : null,
    partner: transportationDTO.partner,
    /**
     * TODO: rename this to transportation with the next refactoring
     */
    freight: transportationDTO.containerNumber,
    containerNumber: transportationDTO.containerNumber,
    vehicle: transportationDTO.vehicle,
    from: transportationDTO.fromAddress,
    to: transportationDTO.toAddress,
    estimatedDateStart: transportationDTO.estimatedDateStart,
    terminal: transportationDTO.terminal,
    truck: transportationDTO.vehicle ? transportationDTO.vehicle.number : null,
    driver: transportationDTO.driver || null,
    phone: transportationDTO.driver ? transportationDTO.driver.phoneNum : null,
    status: mapFreightStatus(transportationDTO.status),
    assignedBy: transportationDTO.assignedBy,
    billingInfo: transportationDTO.billingInfo,
    availableActions: transportationDTO.availableActions.map(mapFreightAction),
    client: transportationDTO.client,
    driverActiveTransportation: transportationDTO.driverActiveTransportation,
    fromTerminal: transportationDTO.fromTerminal
  }
}
