import type { DriverBaseDTO } from '../Driver/DriverBaseDTO'
import type { PartnerBaseDTO } from '../Partner/PartnerBaseDTO'
import type { VehicleBaseDTO } from '../Vehicle/VehicleBaseDTO'

export enum TransportationStatusCodeDTO {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  ARRIVED_LADING = 'ARRIVED_LADING',
  DEPARTED_LADING = 'DEPARTED_LADING',
  ARRIVED_UNLADING = 'ARRIVED_UNLADING',
  STARTED_UNLADING = 'STARTED_UNLADING',
  DEPARTED_UNLADING = 'DEPARTED_UNLADING',
  ARRIVED_EMPTY = 'ARRIVED_EMPTY',
  PASSED_EMPTY = 'PASSED_EMPTY',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  RECOMMENDED = 'RECOMMENDED'
}

export type TransportationStatusDTO = {
  code: TransportationStatusCodeDTO
  value: string
}

type ServiceType = {
  code: string
  value: string
}

type ServiceCost = {
  amount: number
  cost: number
  serviceType: ServiceType
}

export type BillingInfoDTO = {
  totalCost: number
  serviceCosts: ServiceCost[]
}

export enum TransportationActionDTO {
  ASSIGN = 'ASSIGN',
  CANCEL = 'CANCEL',
  APPROVE_OR_REJECT = 'APPROVE_OR_REJECT',
  REPORT_GENERATE = 'REPORT_GENERATE',
  INSTRUCTION_EDIT = 'INSTRUCTION_EDIT'
}

export type TransportationClientDTO = {
  name: string
}

type DriverActiveTransportation = {
  id: number
  returnTerminal: string
}
export type TransportationDTO = {
  id: number
  dateStart: string
  estimatedDateStart: string
  containerNumber: string
  fromAddress: string
  toAddress: string
  terminal: string
  partner?: PartnerBaseDTO
  vehicle?: VehicleBaseDTO
  driver?: DriverBaseDTO
  status: TransportationStatusCodeDTO
  availableActions: TransportationActionDTO[]
  assignedBy?: string
  billingInfo: BillingInfoDTO
  client?: TransportationClientDTO
  driverActiveTransportation?: DriverActiveTransportation
  fromTerminal?: string
}
