import { type DriverBase } from './Driver'
import type { FileInfo } from './FileInfo'
import { type GeoPoint } from './GeoPoint'
import { type MapPoint } from './MapPoint'
import { type PartnerBase } from './Partner'
import { type VehicleBase } from './Vehicle'
export enum FreightStatusCode {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  ARRIVED_LADING = 'ARRIVED_LADING',
  DEPARTED_LADING = 'DEPARTED_LADING',
  ARRIVED_UNLADING = 'ARRIVED_UNLADING',
  STARTED_UNLADING = 'STARTED_UNLADING',
  DEPARTED_UNLADING = 'DEPARTED_UNLADING',
  ARRIVED_EMPTY = 'ARRIVED_EMPTY',
  PASSED_EMPTY = 'PASSED_EMPTY',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  RECOMMENDED = 'RECOMMENDED'
}

export type FreightStatus = {
  code: FreightStatusCode
  value: string
}

export enum SpreadingStatus {
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED',
  DONE = 'DONE'
}

export enum ContainerType {
  CONTAINER_20DC = 'CONTAINER_20DC',
  CONTAINER_40DC = 'CONTAINER_40DC',
  CONTAINER_40HC = 'CONTAINER_40HC',
  CONTAINER_40RF = 'CONTAINER_40RF'
}
export type FreightHistoryItem = {
  status: FreightStatusCode
  date: string
  driver: DriverBase
  changedBy: string
}

type ServiceType = {
  code: string
  value: string
}

type ServiceCost = {
  amount: number
  cost: number
  serviceType: ServiceType
}

export type BillingInfo = {
  totalCost: number
  serviceCosts: ServiceCost[]
}

export enum FreightAction {
  ASSIGN = 'ASSIGN',
  CANCEL = 'CANCEL',
  APPROVE_OR_REJECT = 'APPROVE_OR_REJECT',
  REPORT_GENERATE = 'REPORT_GENERATE',
  INSTRUCTION_EDIT = 'INSTRUCTION_EDIT'
}

/**
 * TODO make type extend each other
 */
export type FreightBase = {
  id: number
  status: FreightStatusCode
}

export type Client = {
  name: string
}
type FreightClient = {
  name: string
}

type DriverActiveTransportation = {
  id: number
  returnTerminal: string
}

export type Container = {
  number: string
  type: ContainerType
  weightKg: number
  costRub?: number
  description?: string
}

export type Freight = {
  id: number
  date: number | null
  partner?: PartnerBase
  vehicle?: VehicleBase
  freight: string
  containerNumber: string
  from: string
  to: string
  estimatedDateStart: string
  terminal: string
  truck: string | null
  driver: DriverBase | null
  phone: string | null
  status: FreightStatusCode
  assignedBy?: string
  billingInfo: BillingInfo
  availableActions: FreightAction[]
  client?: FreightClient
  driverActiveTransportation?: DriverActiveTransportation
  fromTerminal?: string
}

export type FreightInstruction = {
  comment?: string
  attachments?: FileInfo[]
}

export type FreightDetailed = {
  id: number
  containerNumber: string
  containerType: ContainerType
  client?: Client
  containerWeight: number
  fromPoint: MapPoint
  fromSlotTime: string
  fromSlotTimeEstimated: string
  toTime: string
  toTimeEstimated?: string
  returnTimeEstimated?: string
  toPoint: MapPoint
  returnPoint: MapPoint
  returnTerminal?: string
  driverPoint?: GeoPoint
  returnStock: string
  partner?: PartnerBase
  vehicle?: VehicleBase
  driver?: DriverBase
  status: FreightStatusCode
  assignedBy: string
  history: FreightHistoryItem[]
  availableActions: FreightAction[]
  spreadingStatus: SpreadingStatus
  billingInfo: BillingInfo
  fromTerminal?: string
  container: Container
  instruction: FreightInstruction
}
